import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import useUserProfile from '@/lib/api/user/profile'
import useUploadSignatureImage from '@/lib/api/user/upload'
import { cn } from '@/lib/utils'
import type { ChangeEvent } from 'react'

const IMAGE_PLACEHOLDER_DATA =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWltYWdlIj48cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjMiIHJ4PSIyIiByeT0iMiIvPjxjaXJjbGUgY3g9IjkiIGN5PSI5IiByPSIyIi8+PHBhdGggZD0ibTIxIDE1LTMuMDg2LTMuMDg2YTIgMiAwIDAgMC0yLjgyOCAwTDYgMjEiLz48L3N2Zz4='

export default function Profile() {
  const uploadImage = useUploadSignatureImage()
  const { data: profile } = useUserProfile()

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      uploadImage.mutate(file)
    }
  }

  return (
    <section className='flex flex-col gap-6'>
      <h1 className='font-bold text-2xl leading-8'>Extra profile settings</h1>

      <div className='flex flex-col gap-2'>
        <h2 className='text-xl font-medium'>Signature image</h2>
        <div className='w-max h-max relative cursor-pointer group'>
          {uploadImage.isPending && (
            <div className='w-max h-max absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
              <LoadingSpinner className='animate-spin w-10 h-10' />
            </div>
          )}
          <img
            src={profile.signatureImageLink ?? IMAGE_PLACEHOLDER_DATA}
            alt='Profile preview'
            width={240}
            height={240}
            className={cn(
              'opacity-80 group-hover:opacity-60 transition-all duration-200 ease-in-out object-cover rounded-lg',
              uploadImage.isPending && 'opacity-50'
            )}
          />
          <input
            type='file'
            accept='image/*'
            value=''
            onChange={handleImageChange}
            className='opacity-0 absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full h-full cursor-pointer'
          />
        </div>
      </div>
    </section>
  )
}
