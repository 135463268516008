import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import useMedRequestAction from './action'

export default function useApproveMedicationRequest() {
  const { justification, medication, medicationIssueDate } = useMedicationRequestStore()
  const goNextMedRequest = useGoNextMedRequest()

  const { data: originalMedRequest } = useCurrentMedication()
  const action = useMedRequestAction()

  return () => {
    let changeProduct = undefined
    if (
      typeof medication?.wooSKU === 'string' &&
      typeof originalMedRequest?.wooSKU === 'string' &&
      medication.wooSKU !== originalMedRequest?.wooSKU
    ) {
      changeProduct = {
        prevSKU: originalMedRequest?.wooSKU,
        newSKU: medication.wooSKU
      }
    }

    const { notes, medicationDirection, setSidebarView } = useMedicationRequestStore.getState()
    action.mutate({
      id: originalMedRequest.id,
      action: 'approve',
      reason: justification,
      approveDate: medicationIssueDate?.toISOString(),
      additionalNotes: notes,
      extraDirectionsForMedication: medicationDirection,
      changeProduct
    })
    setSidebarView('navigation-menu')
    goNextMedRequest()
  }
}
