import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

type Props = {
  date: Date
  setDate: (date: Date | undefined) => void
  fromDate?: Date
  isDisabled?: boolean
}

export function DatePicker({ date, setDate, fromDate, isDisabled = false }: Props) {
  return (
    <Popover>
      <PopoverTrigger asChild disabled={isDisabled}>
        <button className={`inline-flex gap-2 items-center ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <p className='text-[#153CB7] text-sm font-medium leading-[170%]'>Change</p>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
            <path
              d='M12 22.5H5C3.89 22.5 3 21.6 3 20.5L3.01 6.5C3.01 5.4 3.89 4.5 5 4.5H6V3.5C6 2.95 6.45 2.5 7 2.5C7.55 2.5 8 2.95 8 3.5V4.5H16V3.5C16 2.95 16.45 2.5 17 2.5C17.55 2.5 18 2.95 18 3.5V4.5H19C20.1 4.5 21 5.4 21 6.5V12.5H19V10.5H5V20.5H12V22.5ZM22.13 17.49L22.84 16.78C23.23 16.39 23.23 15.76 22.84 15.37L22.13 14.66C21.74 14.27 21.11 14.27 20.72 14.66L20.01 15.37L22.13 17.49ZM21.42 18.2L16.41 23.21C16.23 23.39 15.97 23.5 15.71 23.5H14.5C14.22 23.5 14 23.28 14 23V21.79C14 21.52 14.11 21.27 14.29 21.08L19.3 16.07L21.42 18.2Z'
              fill='#153CB7'
            />
          </svg>
        </button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <Calendar
          disabled={isDisabled}
          fromDate={fromDate}
          mode='single'
          selected={date}
          onSelect={setDate}
          initialFocus
          className='font-poppins font-semibold text-custom-neutral-600'
          formatters={{
            formatWeekdayName: day => day.toString().substring(0, 1)
          }}
          modifiers={{
            today: new Date()
          }}
          modifiersStyles={{
            today: {
              border: '2px solid currentColor',
              borderRadius: '50%'
            },
            selected: {
              borderRadius: '50%'
            }
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
