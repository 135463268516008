import { Button } from '@/components/ui/button'
import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import { useFilterStore } from '@/lib/stores/filter'
import { useEffect, useRef, type ComponentPropsWithoutRef } from 'react'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useLocation } from 'react-router-dom'

const SHIPPING_TYPES_LABELS: Record<string, string> = {
  'Royal Mail Special Delivery Next Day by 1pm™ Signed For': 'Special Next Day',
  'Royal Mail 24 Tracked™': '24 Tracked',
  'Royal Mail 48 Tracked™': '48 Tracked',
  'Free Shipping': 'Free',
  'Local Collect From Post Office Sent By Royal Mail 24 Tracked™': 'Local Collect'
}

function ToggleButton({
  children,
  onToggle,
  toggle = false
}: {
  onToggle: (value: boolean) => void
  toggle?: boolean
} & ComponentPropsWithoutRef<'button'>) {
  return (
    <Button variant={toggle ? 'toggleOn' : 'toggleOff'} onClick={() => onToggle(!toggle)}>
      {children}
    </Button>
  )
}

const { setFilteringOptions } = useFilterStore.getState()

export default function Filter() {
  const { isPending, data } = useMedRequestFiltering()
  const goNextMedRequest = useGoNextMedRequest()
  const { filteringOptions } = useFilterStore()

  const hasFilters = Object.values(filteringOptions).some(
    value => (typeof value === 'boolean' && value) || (Array.isArray(value) && value.length > 0)
  )

  const location = useLocation()
  useEffect(() => {
    useFilterStore.setState(useFilterStore.getInitialState())
  }, [location])

  const previousFilteringOptions = useRef('')
  useEffect(() => {
    const stamp = Object.values(filteringOptions).reduce((acc, target) => {
      if (Array.isArray(target)) {
        return acc + target.sort().join()
      }
      if (typeof target === 'boolean' || typeof target === 'number') {
        return acc + (target as boolean | string).toString()
      }

      return acc + target
    }, '')

    if (stamp !== previousFilteringOptions.current) {
      previousFilteringOptions.current = stamp
    }
  }, [filteringOptions])

  return (
    <div className='px-10 py-8 rounded-2xl border border-custom-neutral-700 bg-white flex flex-col gap-6'>
      <div className='flex items-center gap-4'>
        <h3 className='font-poppins text-sm leading-5'>Shipping Type:</h3>

        <div className='flex items-center gap-2'>
          {Object.values(SHIPPING_TYPES_LABELS).map((label, index) => (
            <ToggleButton
              key={index}
              toggle={filteringOptions['Shipping Method'].includes(Object.keys(SHIPPING_TYPES_LABELS)[index])}
              onToggle={isOn => {
                const { filteringOptions } = useFilterStore.getState()

                const labelKey = Object.keys(SHIPPING_TYPES_LABELS).find(
                  key => SHIPPING_TYPES_LABELS[key as string] === label
                )
                if (!labelKey) {
                  return
                }

                let existingFilterData = filteringOptions['Shipping Method']
                if (isOn) {
                  existingFilterData = [...existingFilterData, labelKey]
                } else {
                  existingFilterData = existingFilterData.filter(key => key !== labelKey)
                }

                setFilteringOptions({
                  ...filteringOptions,
                  'Shipping Method': [...new Set(existingFilterData)]
                })
              }}
            >
              {label}
            </ToggleButton>
          ))}
        </div>
      </div>

      <div className='min-h-[1px] w-full bg-[#E9EAED]' />

      <div className='flex items-center gap-4'>
        <h3 className='font-poppins text-sm leading-5'>Advanced Filters:</h3>

        <ToggleButton
          toggle={filteringOptions.priority}
          onToggle={isOn => {
            const { filteringOptions } = useFilterStore.getState()
            setFilteringOptions({
              ...filteringOptions,
              priority: isOn
            })
          }}
        >
          Priority
        </ToggleButton>

        <ToggleButton
          toggle={filteringOptions['ID Verified Time'].length > 0}
          onToggle={isOn => {
            const { filteringOptions } = useFilterStore.getState()
            setFilteringOptions({
              ...filteringOptions,
              'ID Verified Time': isOn ? ['before'] : []
            })
          }}
        >
          Before Cutoff
        </ToggleButton>
      </div>

      <Button
        type='button'
        disabled={isPending || !data?.length}
        className='self-center mt-6'
        onClick={() => goNextMedRequest()}
      >
        {isPending
          ? 'Loading'
          : !hasFilters && data?.length
            ? 'View all'
            : data?.length
              ? `View ${data.length}${data.length === 200 ? '+' : ''}`
              : 'No'}{' '}
        medication requests{isPending && '...'}
      </Button>
    </div>
  )
}
