// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/react'
import { useFilterStore } from './lib/stores/filter'
import { useMedicationRequestStore } from './lib/stores/medication-request'

export const initializeSentry = () => {
  Sentry.init({
    environment: import.meta.env.VITE_VERCEL_ENV ?? 'development',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.PROD,
    normalizeDepth: 6,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,

    debug: false,

    beforeSend(event) {
      if (event.exception?.values?.some(e => e.type === 'ChunkLoadError')) {
        return null
      }
      if (event.exception?.values?.some(e => e.type === 'TypeError' && e.value === 'Load failed')) {
        return null
      }
      if (event.exception?.values?.some(e => e.type === 'TypeError' && e.value === 'Load failed')) {
        return null
      }

      event.user = window.Clerk ? (window.Clerk.user as any) : undefined
      event.contexts = {
        ...event.contexts,
        store: {
          filter: useFilterStore.getState(),
          medRequest: useMedicationRequestStore.getState()
        }
      }

      event.tags = {
        ...(event.tags ?? {})
      }

      return event
    },

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const text = !hint?.event.target.childElementCount
          ? hint?.event.target.innerText
          : hint.event.target.getAttribute('aria-label')
        if (text) {
          breadcrumb.message = text
        }
      }

      return breadcrumb
    }

    // replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    // integrations: [
    //   Sentry.replayIntegration({
    //     // Additional Replay configuration goes in here, for example:
    //     maskAllText: true,
    //     blockAllMedia: true,
    //   }),
    // ],
  })
}
