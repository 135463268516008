import { cn } from '@/lib/utils'

const SensorRounded = ({ ...props }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn(props.className)}
      {...props}
    >
      <path
        d='M12.0001 11.0001C13.6601 11.0001 15.0001 9.66006 15.0001 8.00006C15.0001 6.34006 13.6601 5.00006 12.0001 5.00006C10.3401 5.00006 9.00012 6.34006 9.00012 8.00006C9.00012 9.66006 10.3401 11.0001 12.0001 11.0001Z'
        fill='#4E545E'
      />
      <path
        d='M12.0001 12.0001C10.1601 12.0001 8.44012 12.5001 6.97012 13.3701C6.36012 13.7301 6.00012 14.3901 6.00012 15.0901V16.0001C6.00012 16.5501 6.45012 17.0001 7.00012 17.0001H17.0001C17.5501 17.0001 18.0001 16.5501 18.0001 16.0001V15.0901C18.0001 14.3901 17.6401 13.7301 17.0301 13.3701C15.5601 12.5001 13.8401 12.0001 12.0001 12.0001Z'
        fill='#4E545E'
      />
      <path
        d='M22.1102 7.79006C22.6602 7.56006 22.8902 6.91006 22.6102 6.38006C21.4802 4.26006 19.7402 2.52006 17.6202 1.39006C17.1002 1.11006 16.4502 1.35006 16.2202 1.89006C16.0302 2.36006 16.2102 2.91006 16.6502 3.14006C18.4402 4.08006 19.9102 5.56006 20.8602 7.35006C21.0902 7.80006 21.6402 7.98006 22.1102 7.79006Z'
        fill='#4E545E'
      />
      <path
        d='M7.79008 1.89007C7.56008 1.34007 6.91008 1.11007 6.39008 1.39007C4.27008 2.52007 2.52008 4.26007 1.40008 6.38007C1.12008 6.90007 1.35008 7.56007 1.90008 7.79007C2.37008 7.99007 2.92008 7.80007 3.15008 7.36007C4.09008 5.57007 5.57008 4.10007 7.36008 3.15007C7.80008 2.91007 7.98008 2.36007 7.79008 1.89007Z'
        fill='#4E545E'
      />
      <path
        d='M1.89007 16.2101C1.34007 16.4401 1.11007 17.0901 1.39007 17.6101C2.52007 19.7301 4.26007 21.4801 6.39007 22.6101C6.91007 22.8901 7.56007 22.6501 7.79007 22.1101C7.98007 21.6401 7.80007 21.0901 7.36007 20.8601C5.57007 19.9201 4.10007 18.4401 3.15007 16.6501C2.91007 16.2001 2.36007 16.0201 1.89007 16.2101Z'
        fill='#4E545E'
      />
      <path
        d='M16.2101 22.1101C16.4401 22.6601 17.0901 22.8901 17.6101 22.6101C19.7301 21.4801 21.4801 19.7401 22.6101 17.6101C22.8901 17.0901 22.6501 16.4401 22.1101 16.2101C21.6401 16.0201 21.0901 16.2001 20.8601 16.6401C19.9201 18.4301 18.4401 19.9001 16.6501 20.8501C16.2001 21.0901 16.0201 21.6401 16.2101 22.1101Z'
        fill='#4E545E'
      />
    </svg>
  )
}

export default SensorRounded
