import LockIcon from '@/components/ui/icons/lock'
import useAllMedRequestHistory from '@/lib/api/med-requests/all-history'
import type { MedRequest } from '@/types/data'
import { useUser } from '@clerk/clerk-react'
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { format, parseISO } from 'date-fns'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
// import LockIcon from "@/components/ui/icons/lock";
import BackIcon from '@/components/ui/icons/back'
import ForwardIcon from '@/components/ui/icons/forward'
import { cn, getOrderStatusBadgeClasses } from '@/lib/utils'
import { Link } from 'react-router-dom'
import Prescriber from './prescriber'
import { Request } from './request'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columns: ColumnDef<MedRequest, any>[] = [
  {
    accessorKey: 'medication-request',
    header: 'Medication Request',
    cell: ({ row }) => <Request {...row.original} />
  },
  {
    accessorKey: 'patient=name',
    header: 'Patient',
    cell: ({ row }) => {
      const { patient } = row.original
      return (
        <span className='font-poppins text-sm leading-6 text-custom-neutral-800 capitalize'>
          {`${patient.firstName} ${patient.lastName}`}
        </span>
      )
    }
  },
  {
    accessorKey: 'current-stage',
    header: 'Current stage',
    cell: ({ row }) => {
      const { stage } = row.original

      return (
        <span
          className={cn(
            'px-4 py-2 border grid place-content-center rounded-2xl w-max',
            getOrderStatusBadgeClasses(stage)
          )}
        >
          {stage}
        </span>
      )
    }
  },
  {
    accessorKey: 'last-update-by',
    header: () => 'Last update by',
    cell: ({ row }) => {
      const { prescriber } = row.original
      return <Prescriber {...prescriber} />
    }
  },
  {
    accessorKey: 'id-verified-time',
    header: () => 'ID Verified Time',
    cell: ({ row }) => {
      const idVerifiedTime = row.original?.idVerifiedTime ?? ''

      const formattedDate = idVerifiedTime ? format(parseISO(idVerifiedTime), 'dd-MM-yyyy HH:mm') : ''

      return <span className='text-sm leading-6 text-custom-neutral-800 capitalize'>{formattedDate}</span>
    }
  },
  {
    id: 'actions',
    accessorKey: 'locked',
    header: '',
    enableHiding: false,
    cell: ({ row }) => {
      const { locked } = row.original

      if (!locked) return null

      return <LockIcon />
    }
  }
]

export function PrescribingHistoryTable() {
  const { user } = useUser()
  const showLastUpdatedBy = user?.publicMetadata.roles.includes('LEAD_PRESCRIBER')

  const { data } = useAllMedRequestHistory()

  const table = useReactTable({
    data,
    columns: showLastUpdatedBy ? columns : (columns as any[]).filter(e => e.accessorKey !== 'last-update-by'),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <div className='w-full'>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow className='bg-custom-gray-3' key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => {
                  const isFirstItem = i === 0
                  return (
                    <TableHead
                      className={`${
                        isFirstItem ? 'pl-6' : 'pl-4'
                      } h-14 font-medium text-sm leading-6 text-custom-neutral-900`}
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell className='p-4' key={cell.id}>
                      <Link to={`/request/${row.original.id}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Link>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className='font-poppins h-[44px] text-xs leading-[166%] text-custom-neutral-800 flex gap-[26px] items-center justify-end py-[2px]'>
        <div className='text-xs text-custom-neutral-800 leading-[166%] tracking-[0.4%]'>
          Rows per page:
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className='text-xs leading-[166%] text-custom-indigo-600 tracking-[0.4%]'>
          {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
          {Math.min(
            (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
            table.getFilteredRowModel().rows.length
          )}{' '}
          of {table.getFilteredRowModel().rows.length}
        </div>
        <div className=''>
          <Button
            variant='outline'
            className='border-0 shadow-none'
            size='sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BackIcon />
          </Button>
          <Button
            variant='outline'
            size='sm'
            className='border-0 shadow-none'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ForwardIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
