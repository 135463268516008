import { useEffect } from 'react'
import { toast } from '@/hooks/use-toast'
import { useMutation } from '@tanstack/react-query'
import http from '@/lib/http'

const useScreenshotAlert = () => {
  const { mutate } = useMutation({
    mutationFn: () => {
      return http.post('/prescriber/lock-user')
    }
  })

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Mac screenshot shortcuts, according to chat gpt native screenshotting is blocking
      // const isMacScreenshot = (e.key === '3' || e.key === '4') && e.metaKey && e.shiftKey

      // Windows screenshot shortcuts
      const isWindowsScreenshot =
        e.key === 'PrintScreen' || (e.key === 'S' && e.getModifierState('Meta')) || (e.key === 'PrtScn' && e.altKey)

      // Linux screenshot shortcuts
      const isLinuxScreenshot =
        e.key === 'Print' ||
        e.key === 'PrintScreen' ||
        (e.key === 'Print' && e.ctrlKey) || // Save to clipboard
        (e.key === 'Print' && e.altKey) // Window screenshot

      // Print shortcuts
      const isPrintCommand =
        (e.key === 'p' && e.metaKey) || // Mac print
        (e.key === 'p' && e.ctrlKey) // Windows print

      if (isWindowsScreenshot || isLinuxScreenshot || isPrintCommand) {
        toast({
          description: 'Screenshot detected',
          variant: 'destructive'
        })
        mutate()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])
}

export default useScreenshotAlert
