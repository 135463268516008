import { cn } from '@/lib/utils'

const ForwardIcon = ({ ...props }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn(props.className)}
      {...props}
    >
      <path
        d='M9.70498 6L8.29498 7.41L12.875 12L8.29498 16.59L9.70498 18L15.705 12L9.70498 6Z'
        className='fill-custom-gray-4'
      />
    </svg>
  )
}

export default ForwardIcon
