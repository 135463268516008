import { CheckIcon } from '@radix-ui/react-icons'
import * as React from 'react'

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import ArrowDown from '@/components/ui/icons/arrow-down'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { useVirtualizer } from '@tanstack/react-virtual'

export default function SingleCombobox({
  options,
  title,
  onValueChange,
  defaultValue: value,
  isDisabled = false
}: {
  options: Array<{ label: string; value: string }>
  title: string
  onValueChange: (value: string) => void
  defaultValue?: string
  isDisabled?: boolean
}) {
  const [open, setOpen] = React.useState(false)
  const [parentNode, setParentNode] = React.useState<HTMLDivElement | null>(null)

  const [searchQuery, setSearchQuery] = React.useState('')
  const isLabelValid = (label: string) => label.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1

  const filteredView = React.useMemo(
    () => options.filter(option => isLabelValid(option.label)),
    [open, searchQuery, options]
  )

  const virtualizerProps = React.useMemo(
    () => ({
      count: filteredView ? filteredView.length : 0,
      getScrollElement: () => parentNode,
      estimateSize: () => 32,
      overscan: 5
    }),
    [filteredView, parentNode]
  )
  const rowVirtualizer = useVirtualizer(virtualizerProps)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          disabled={isDisabled}
          type='button'
          role='combobox'
          aria-expanded={open}
          className={cn(
            'py-4 px-3 rounded-2xl border border-custom-neutral-200 bg-transparent flex gap-4 items-center justify-between transition-all duration-200 ease-in-out hover:bg-secondary/20 w-full relative',
            isDisabled && 'opacity-50 cursor-not-allowed hover:bg-transparent'
          )}
        >
          <div className='absolute left-4 -top-2 text-xs leading-3 tracking-[0.15px] text-custom-neutral-800 p-1 bg-white rounded-lg'>
            {title}
          </div>

          <span className='text-base font-normal leading-6 tracking-[0.15px]'>
            {value ? options.find(option => option.value === value)?.label : 'Select an option...'}
          </span>

          <ArrowDown className='ml-auto' />
        </button>
      </PopoverTrigger>
      <PopoverContent className='w-[424px] max-h-full overflow-hidden [&_div]:overflow-hidden'>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder='Search option...'
            className='h-9'
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
          <CommandList>
            <CommandEmpty>No option found.</CommandEmpty>
            <CommandGroup>
              <div ref={setParentNode} className='!overflow-auto w-full h-max max-h-[248px]'>
                <div
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  {rowVirtualizer.getVirtualItems().map(row => {
                    const option = filteredView[row.index]
                    if (!option || (searchQuery.length > 0 && !isLabelValid(option.label))) {
                      return null
                    }

                    return (
                      <CommandItem
                        key={`${option.label}-${row.index}`}
                        value={option.label}
                        onSelect={() => {
                          onValueChange(option.value)
                          setOpen(false)
                        }}
                        data-index={row.index}
                        className='absolute left-0 top-0 w-full'
                        style={{
                          height: `${row.size}px`,
                          transform: `translateY(${row.start}px)`
                        }}
                      >
                        {option.label}
                        <CheckIcon
                          className={cn('ml-auto h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')}
                        />
                      </CommandItem>
                    )
                  })}
                </div>
              </div>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
