export default function MedicationRounded({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17 3.5H7C6.45 3.5 6 3.95 6 4.5C6 5.05 6.45 5.5 7 5.5H17C17.55 5.5 18 5.05 18 4.5C18 3.95 17.55 3.5 17 3.5Z'
        fill='#4E545E'
      />
      <path
        d='M17 6.5H7C5.9 6.5 5 7.4 5 8.5V19.5C5 20.6 5.9 21.5 7 21.5H17C18.1 21.5 19 20.6 19 19.5V8.5C19 7.4 18.1 6.5 17 6.5ZM14.5 15.5H13.5V16.5C13.5 17.33 12.83 18 12 18C11.17 18 10.5 17.33 10.5 16.5V15.5H9.5C8.67 15.5 8 14.83 8 14C8 13.17 8.67 12.5 9.5 12.5H10.5V11.5C10.5 10.67 11.17 10 12 10C12.83 10 13.5 10.67 13.5 11.5V12.5H14.5C15.33 12.5 16 13.17 16 14C16 14.83 15.33 15.5 14.5 15.5Z'
        fill='#4E545E'
      />
    </svg>
  )
}
