import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { cn, getPatientAgeText } from '@/lib/utils'
import type { MedRequest } from '@/types/data'
import { useMemo } from 'react'

export function Column({
  header,
  text
}: {
  header: string
  text: string | null
}) {
  return (
    <div className='w-2/12'>
      <p className='mb-2 text-[#1D1D1D] font-bold leading-[170%]'>{header}</p>
      <p className='text-custom-neutral-800 leading-[170%]'>{text ?? '-'}</p>
    </div>
  )
}

export default function PatientData() {
  const { data: medRequest } = useCurrentMedication()

  const patient = medRequest.patient

  const patientAgeText = useMemo(() => getPatientAgeText(patient?.dob), [patient?.dob])

  const patientName = patient?.firstName ? `${patient?.firstName} ${patient?.lastName}` : '-'

  const optionalFields = useMemo(() => {
    return (
      ['familyHistory', 'allergies', 'conditions', 'medications', 'socialHistory', 'otherObservations'] as Array<
        keyof MedRequest['patient']
      >
    )
      .map(field => {
        if (medRequest.patient[field]) {
          let label: string = field
          if (field === 'otherObservations') {
            label = 'Other'
          } else if (field === 'socialHistory') {
            label = 'Social history'
          } else if (field === 'familyHistory') {
            label = 'Family history'
          }

          return {
            label,
            value: patient[field]
          }
        }
      })
      .filter(Boolean)
  }, [medRequest])

  return (
    <div id='patient-data'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-4'>Patient data</p>
      <div className='flex flex-col gap-10 rounded-2xl border border-custom-neutral-700 bg-white py-8 px-12'>
        <div className='flex h-[128px] justify-between'>
          <Column header='Patient name' text={patientName} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Age' text={patientAgeText} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Gender' text={patient?.gender as string} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Postcode' text={medRequest.orderPostCode} />
        </div>

        {!!optionalFields?.length && (
          <>
            <div className='min-h-[1px] bg-custom-neutral-700 w-full' />

            <div className='flex flex-wrap gap-[50px] w-full items-start'>
              {optionalFields.map((field, index) => {
                if (!field?.value) return null

                if (index % 2 === 0 && optionalFields[index + 1]) {
                  return (
                    <div key={index} className={cn('flex gap-[50px] w-[calc(50%_-_51px)]', index > 1 && 'mt-10')}>
                      <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#1D1D1D] font-bold text-base leading-7 capitalize'>{field.label}</p>
                        <p className='text-custom-neutral-800 text-base leading-7'>{field.value}</p>
                      </div>

                      <div className='min-h-full w-[1px] bg-custom-neutral-700' />
                    </div>
                  )
                }

                return (
                  <div key={index} className={cn('w-[calc(50%_-_101px)] flex flex-col gap-2', index > 1 && 'mt-10')}>
                    <p className='text-[#1D1D1D] font-bold text-base leading-7 capitalize'>{field.label}</p>
                    <p className='text-custom-neutral-800 text-base leading-7'>{field.value}</p>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
