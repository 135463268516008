import { useCallback, useEffect, useRef, useState } from 'react'
import { Search } from 'lucide-react'
import { Input } from './input'
import { cn } from '@/lib/utils'
import Cross from '@/components/ui/icons/cross'
import { useFilterStore } from '@/lib/stores/filter'

export default function SearchInput({
  value,
  onChange,
  onSubmit,
  onCancel
}: {
  onChange?: (value: string) => void
  onSubmit?: (value: string) => void
  onCancel?: () => void
  value?: string
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const { filteringOptions } = useFilterStore()
  useEffect(() => {
    if (
      (filteringOptions['ID Verified Time'].length > 0 || filteringOptions['Shipping Method'].length > 0) &&
      !!inputRef.current?.value.length
    ) {
      if (inputRef.current) {
        inputRef.current.value = ''
      }

      setIsExpanded(false)
      onCancel?.()
    }
  }, [filteringOptions])

  useEffect(() => {
    if (value) {
      setIsExpanded(true)
    }
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = value ?? ''
      }
    })
  }, [value])

  const expand = useCallback(() => {
    if (!isExpanded) {
      setIsExpanded(true)
    }
  }, [isExpanded])

  return (
    <div
      className={cn(
        'w-max h-max p-3 rounded-2xl border border-[#E9EAED] cursor-pointer transition-all duration-200 ease-in-out hover:bg-gray-300 select-none',
        isExpanded && 'py-4 rounded-sm hover:bg-transparent cursor-auto'
      )}
      onClick={expand}
    >
      {!isExpanded && <Search className='text-[#4E545E] min-h-5 min-w-5' />}
      {isExpanded && (
        <div className='relative w-max h-max'>
          <Input
            ref={inputRef}
            className='border-none outline-none focus-visible:ring-0 shadow-none h-6 placeholder:text-[#A0A3A6]'
            placeholder='Search for order number'
            onChange={e => {
              onChange?.(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && inputRef.current) {
                onSubmit?.(inputRef.current.value)
              }
            }}
            autoFocus
          />

          <button
            type='button'
            className='p-1 cursor-pointer absolute right-0 top-1/2 -translate-y-1/2 transition-all duration-200 ease-in-out hover:bg-gray-200'
            onClick={() => {
              setIsExpanded(false)
              onCancel?.()
            }}
          >
            <Cross className='h-3 w-3 text-black/50' />
          </button>
        </div>
      )}
    </div>
  )
}
