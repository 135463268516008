import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { PersistStorage } from 'zustand/middleware'

export interface IFilter {
  medication: Array<string>
  treatmentCategory: Array<string>
  flags: Array<string>
  shippingMethod: Array<string>
}

export interface SavedFilters extends IFilter {
  name: string
}

export type FilteringOptionKey = 'Order Number' | 'ID Verified Time' | 'Shipping Method' | 'priority'

export type FilteringOptions = {
  'Order Number': Array<string>
  'ID Verified Time': Array<string>
  'Shipping Method': Array<string>
  priority: boolean
}

export interface FilterStore {
  filteringOptions: FilteringOptions
  setFilteringOptions: (options: FilteringOptions) => void

  lastAppliedFilter: IFilter | null
  setLastAppliedFilter: (filter: IFilter | null) => void

  savedFilters: Array<SavedFilters>
  setSavedFilters: (filters: Array<SavedFilters>) => void

  selectedSavedFilter: number
  setSelectedSavedFilter: (index: number) => void

  editingSavedFilter: number
  setEditingSavedFilter: (index: number) => void
  getCacheKey: () => Array<string | undefined>
}

const filterStorage: PersistStorage<FilterStore> = {
  getItem: name => {
    const str = localStorage.getItem(name)
    if (!str) return null
    return JSON.parse(str)
  },
  setItem: (name, value) => {
    localStorage.setItem(name, JSON.stringify(value))
  },
  removeItem: name => localStorage.removeItem(name)
}

const useFilterStore = create<FilterStore>()(
  persist(
    (set, get) => ({
      filteringOptions: {
        'Order Number': [],
        'ID Verified Time': [],
        'Shipping Method': [],
        priority: false
      },
      setFilteringOptions: options => set({ filteringOptions: options }),

      selectedSavedFilter: -1,
      setSelectedSavedFilter: index => set({ selectedSavedFilter: index }),

      editingSavedFilter: -1,
      setEditingSavedFilter: index => set({ editingSavedFilter: index }),

      lastAppliedFilter: null,
      setLastAppliedFilter: filter => set({ lastAppliedFilter: filter }),

      savedFilters: [],
      setSavedFilters: filters => set({ savedFilters: filters }),
      getCacheKey: () => {
        const { filteringOptions } = get()
        return [
          'medRequestFiltering',
          ...Object.values(filteringOptions).map(entry =>
            Array.isArray(entry) ? entry.sort().join() : entry.toString()
          )
        ]
      }
    }),
    {
      name: 'filter-store',
      storage: filterStorage
    }
  )
)

export { useFilterStore }
