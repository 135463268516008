import { cn } from '@/lib/utils'
import type { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Logo from '../ui/icons/logo'
import { Toaster } from '../ui/toaster'
import UserWidget from '../ui/user'

export default function RootLayout({ children }: { children: ReactNode }) {
  const location = useLocation()
  const selected = location.pathname

  return (
    <main className='min-h-[100vh] w-full flex flex-col'>
      <div className='fixed left-0 top-0 w-full flex items-center justify-between py-3 px-8 border-b border border-custom-neutral-700 bg-white z-[10]'>
        <Link to='/'>
          <Logo />
        </Link>

        <nav className='flex items-center gap-[4.75rem]'>
          <Link
            to='/'
            className={cn(
              'text-base leading-4 transition-all duration-200 ease-in-out text-custom-neutral-600 font-medium',
              selected === '/' && 'font-bold text-secondary'
            )}
          >
            Dashboard
          </Link>
          <Link
            to='/history'
            className={cn(
              'text-base leading-4 transition-all duration-200 ease-in-out text-custom-neutral-600 font-medium',
              selected === '/history' && 'font-bold text-secondary'
            )}
          >
            History
          </Link>
        </nav>

        <UserWidget />
      </div>

      <div className='mt-[8rem] relative px-8 pb-[8.125rem]'>{children}</div>
      <Toaster />
    </main>
  )
}
