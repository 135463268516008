import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { ConsultationTable } from '../consultation-table'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'

export default function Consultation() {
  const { data } = useCurrentMedication()
  const { parsedQuestions } = useQuestionnaire(data.questionaire)

  if (!parsedQuestions) return null

  return (
    <div id='consultation'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>Consultation</p>
      <ConsultationTable data={parsedQuestions} />
    </div>
  )
}
