import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { useLockMedicationById, useMedicationRequestById } from '@/services/medicationRequests'
import { useParams } from 'react-router-dom'

const usePrepareNextRequest = () => {
  const { id: currentMedicationId } = useParams<{ id: string }>()

  const { data } = useMedRequestFiltering()
  const nextMedication = data?.find(e => e.id !== currentMedicationId)

  useMedicationRequestById(nextMedication?.id ?? '', !!nextMedication)
  useLockMedicationById(nextMedication?.id ?? '', !!nextMedication)
  usePatientOrderHistory(nextMedication?.patient?.id ?? '', !!nextMedication)

  return nextMedication
}

export default usePrepareNextRequest
