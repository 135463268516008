import { cn } from '@/lib/utils'

const Lock2 = ({ ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      className={cn(props.className)}
      {...props}
    >
      <path
        d='M18 11.5C18.7 11.5 19.37 11.6 20 11.79V10.5C20 9.4 19.1 8.5 18 8.5H17V6.5C17 3.74 14.76 1.5 12 1.5C9.24 1.5 7 3.74 7 6.5V8.5H6C4.9 8.5 4 9.4 4 10.5V20.5C4 21.6 4.9 22.5 6 22.5H12.26C11.47 21.37 11 19.99 11 18.5C11 14.63 14.13 11.5 18 11.5ZM9 6.5C9 4.84 10.34 3.5 12 3.5C13.66 3.5 15 4.84 15 6.5V8.5H9V6.5Z'
        fill='#FF8080'
      />
      <path
        d='M18 13.5C15.24 13.5 13 15.74 13 18.5C13 21.26 15.24 23.5 18 23.5C20.76 23.5 23 21.26 23 18.5C23 15.74 20.76 13.5 18 13.5ZM20 20.5C19.8 20.7 19.49 20.7 19.29 20.5L17.64 18.85C17.55 18.76 17.49 18.63 17.49 18.5V16C17.49 15.72 17.71 15.5 17.99 15.5C18.27 15.5 18.49 15.72 18.49 16V18.29L19.99 19.79C20.2 19.99 20.2 20.3 20 20.5Z'
        fill='#FF8080'
      />
    </svg>
  )
}

export default Lock2
