type Props = {
  firstName: string | null
  lastName: string | null
}

export default function Prescriber({ firstName, lastName }: Props) {
  const fullName = `${firstName} ${lastName}`
  const initials = `${firstName?.[0] ?? '?'}${lastName?.[0] ?? '?'}`.toUpperCase()

  if (!firstName && !lastName) {
    return <p className='text-base  leading-[27.2px] font-medium text-[#1D1D1D]'>{'N/A'}</p>
  }

  return (
    <div className='flex items-center gap-2'>
      <div className='grid place-content-center w-12 h-12 rounded-2xl bg-[#EBEFFA] text-custom-blue-600 font-medium text-sm'>
        {initials}
      </div>
      <div>
        <p className='text-base  leading-[27.2px] font-medium text-[#1D1D1D]'>{fullName}</p>
      </div>
    </div>
  )
}
