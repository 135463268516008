import http from '@/lib/http'
import type { UserProfile } from '@/types/data'
import { useSuspenseQuery } from '@tanstack/react-query'

const useUserProfile = () => {
  return useSuspenseQuery<UserProfile>({
    queryKey: ['userProfile'],
    queryFn: () => http.get('/prescriber/profile')
  })
}

export default useUserProfile
