export default function DocumentRounded({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3 6.5C2.45 6.5 2 6.05 2 5.5V2.5C2 1.95 2.45 1.5 3 1.5H6C6.55 1.5 7 1.95 7 2.5C7 3.05 6.55 3.5 6 3.5H4V5.5C4 6.05 3.55 6.5 3 6.5ZM17 2.5C17 3.05 17.45 3.5 18 3.5H20V5.5C20 6.05 20.45 6.5 21 6.5C21.55 6.5 22 6.05 22 5.5V2.5C22 1.95 21.55 1.5 21 1.5H18C17.45 1.5 17 1.95 17 2.5ZM3 18.5C2.45 18.5 2 18.95 2 19.5V22.5C2 23.05 2.45 23.5 3 23.5H6C6.55 23.5 7 23.05 7 22.5C7 21.95 6.55 21.5 6 21.5H4V19.5C4 18.95 3.55 18.5 3 18.5ZM17 22.5C17 23.05 17.45 23.5 18 23.5H21C21.55 23.5 22 23.05 22 22.5V19.5C22 18.95 21.55 18.5 21 18.5C20.45 18.5 20 18.95 20 19.5V21.5H18C17.45 21.5 17 21.95 17 22.5ZM19 18.5C19 19.6 18.1 20.5 17 20.5H7C5.9 20.5 5 19.6 5 18.5V6.5C5 5.4 5.9 4.5 7 4.5H17C18.1 4.5 19 5.4 19 6.5V18.5ZM9 9.5C9 10.05 9.45 10.5 10 10.5H14C14.55 10.5 15 10.05 15 9.5C15 8.95 14.55 8.5 14 8.5H10C9.45 8.5 9 8.95 9 9.5ZM9 12.5C9 13.05 9.45 13.5 10 13.5H14C14.55 13.5 15 13.05 15 12.5C15 11.95 14.55 11.5 14 11.5H10C9.45 11.5 9 11.95 9 12.5ZM9 15.5C9 16.05 9.45 16.5 10 16.5H14C14.55 16.5 15 16.05 15 15.5C15 14.95 14.55 14.5 14 14.5H10C9.45 14.5 9 14.95 9 15.5Z'
        fill='#4E545E'
      />
    </svg>
  )
}
