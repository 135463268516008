import http from '@/lib/http'

import type { Flag } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

export const useGetFlags = () => {
  return useQuery({
    queryKey: ['getFlags'],
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: () => http.get<Record<string, Flag>, Record<string, Flag>>('/prescriber/flags')
  })
}
