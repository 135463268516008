import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { isMedRequestInEditableStage } from '@/lib/utils'
import { useEffect } from 'react'
import TextareaWithLabel from '../textarea'
import type { AdditionalNotesType } from '@/types/data'

export default function AdditionalNotes({ isEditable }: { isEditable: boolean }) {
  const { notes, setNotes, medicationIssueDate, medication } = useMedicationRequestStore()

  const { data } = useCurrentMedication()

  useEffect(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const oldNotes = useMedicationRequestStore.getState().notes
    oldNotes.pharmacist = oldNotes.pharmacist?.replace(/\nMedication future date:.*\n/g, '')

    if (medicationIssueDate && medicationIssueDate?.getTime() > today.getTime()) {
      oldNotes.pharmacist += `\nMedication future date: ${medicationIssueDate.toLocaleString('en-GB', {
        dateStyle: 'full',
        timeStyle: 'long'
      })}\n`
    }

    setNotes(oldNotes)
  }, [medicationIssueDate])

  useEffect(() => {
    setNotes({
      pharmacist: data.additionalNotes?.pharmacist ?? '',
      customerService: data.additionalNotes?.customerService ?? ''
    })
  }, [data.id])

  if (medication?.type === 'OTC') {
    return null
  }

  return (
    <div id='add-notes' className='flex flex-col gap-5'>
      {(['pharmacist', 'customerService'] as (keyof AdditionalNotesType)[]).map(entry => {
        let formattedTitle = ''
        if (entry === 'pharmacist') {
          formattedTitle = 'Pharmacy'
        } else if (entry === 'customerService') {
          formattedTitle = 'Customer Service'
        }

        return (
          <div key={entry}>
            <span className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>
              {formattedTitle} notes{' '}
              <span className='text-custom-neutral-600  font-normal leading-[170%]'>(Optional)</span>
            </span>
            <p className='text-[#1D1D1D] font-medium leading-[170%] mb-4'>Add Notes</p>
            <TextareaWithLabel
              isDisabled={!isEditable}
              title={`${formattedTitle} notes`}
              value={notes?.[entry] ?? ''}
              onChange={e => {
                const { notes: newNotes } = useMedicationRequestStore.getState()
                setNotes({
                  ...newNotes,
                  [entry]: e.target.value
                })
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
