import http from '@/lib/http'
import type { Medication } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

export const useGetMedications = () => {
  return useQuery({
    queryKey: ['getMedications'],
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: () => http.get<Array<Medication>, Array<Medication>>('/prescriber/medications')
  })
}
