import { cn } from '@/lib/utils'

export const CancelCircle = ({ ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='89'
      height='88'
      viewBox='0 0 89 88'
      className={cn(props.className)}
      {...props}
    >
      <path
        opacity='0.4'
        d='M5.08398 44.0002C5.08398 65.7695 22.7314 83.4168 44.5007 83.4168C66.27 83.4168 83.9173 65.7695 83.9173 44.0002C83.9173 22.2309 66.27 4.5835 44.5007 4.5835C22.7314 4.5835 5.08398 22.2309 5.08398 44.0002Z'
        fill='#FF4D1A'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M58.0933 30.4075C59.5252 31.8395 59.5252 34.1611 58.0933 35.593L49.6857 44.0002L58.0933 52.4075C59.5252 53.8393 59.5252 56.1607 58.0933 57.5929C56.6615 59.0247 54.3401 59.0247 52.9079 57.5929L44.4999 49.1856L36.0933 57.5914C34.6613 59.0232 32.3397 59.0232 30.9078 57.5914C29.476 56.1596 29.476 53.8378 30.908 52.406L39.3141 44.0002L30.908 35.5944C29.476 34.1625 29.476 31.8409 30.9078 30.409C32.3397 28.977 34.6613 28.9769 36.0933 30.4088L44.4999 38.8148L52.9079 30.4073C54.3401 28.9755 56.6615 28.9756 58.0933 30.4075Z'
        fill='#FF4D1A'
      />
    </svg>
  )
}
