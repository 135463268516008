import { useLockMedicationById, useSuspenseMedicationRequestById } from '@/services/medicationRequests'
import { useParams } from 'react-router-dom'
import usePrepareNextRequest from './usePrepareNextRequest'

export const useCurrentMedication = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    throw new Error('No ID provided')
  }
  const medRequest = useSuspenseMedicationRequestById(id)

  useLockMedicationById(id, !medRequest.data.locked)
  usePrepareNextRequest()

  return medRequest
}
