import AdditionalNotes from './sections/additional-notes'
import Consultation from './sections/consultation'
import ContactPatientSection from './sections/contact-patient-section'
import Footer from './sections/footer'
import NavigationMenu from './sections/nav-menu'
import OnHoldHistory from './sections/on-hold-history'
import PatientData from './sections/patient-data'
import PrescriptionDetails from './sections/prescription-details'
import PreviousOrders from './sections/previous-orders'
import RejectionReasonSection from './sections/rejection-reason-section'

import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { isMedRequestInEditableStage, isRoleAllowedToEdit } from '@/lib/utils'
import PostOrderView from './post-order-view'
import ApprovalReasonSection from './sections/approval-reason-section'
import Header from './sections/header'
import SCRDataSection from './sections/scr-data'
import { useUser } from '@clerk/clerk-react'

const MedicationRequestPage = () => {
  const { sidebarView, status } = useMedicationRequestStore()
  const { user } = useUser()
  const { data } = useCurrentMedication()

  const isEditable =
    isMedRequestInEditableStage(data.stage) && isRoleAllowedToEdit(data, user?.publicMetadata.roles ?? [])

  const success = status !== 'pending'

  const hideFooter = sidebarView !== 'navigation-menu' || !isEditable

  if (success) return <PostOrderView />

  return (
    <section key={data.id} className='flex flex-col gap-8'>
      <Header />
      <div className='flex gap-[37px] flex-nowrap w-full justify-between'>
        <div className='flex flex-col gap-14 w-full'>
          <PatientData />
          <ErrorBoundary fallback='Error Loading Previous Orders'>
            <PreviousOrders />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading Consultation Data'>
            <OnHoldHistory />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading Consultation Data'>
            <Consultation />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading SCR Data'>
            <SCRDataSection />
          </ErrorBoundary>
          <PrescriptionDetails isEditable={isEditable} />
          {/*   <PrescriberJustification /> */}
          <AdditionalNotes isEditable={isEditable} />
        </div>
        {sidebarView === 'navigation-menu' && <NavigationMenu />}
        {sidebarView === 'approval-reason' && <ApprovalReasonSection />}
        {sidebarView === 'contact-patient' && <ContactPatientSection />}
        {sidebarView === 'rejection' && <RejectionReasonSection />}
      </div>
      {!hideFooter && <Footer />}
    </section>
  )
}

export default MedicationRequestPage
