import { useUser } from '@clerk/clerk-react'
import type { ReactNode } from 'react'

const Protect = ({
  children,
  fallback,
  role,
  roles
}: {
  children: ReactNode
  fallback?: ReactNode
  role?: UserPublicMetadata['roles'][0]
  roles?: UserPublicMetadata['roles']
}) => {
  const { isSignedIn, user } = useUser()

  if (!isSignedIn) return fallback
  if (import.meta.env.VITE_VERCEL_ENV === 'production' && !user.twoFactorEnabled)
    return (
      <div>
        <p>Please enable two factor authentication</p>
        <a href='https://account.evaro.com/#/security' target='_blank' rel='noreferrer'>
          Click here for adding 2FA
        </a>
      </div>
    )
  if (!user.publicMetadata.prescriberId) return fallback

  if (role && !user.publicMetadata.roles.includes(role)) return fallback
  if (roles && !user.publicMetadata.roles.some(role => roles.includes(role))) return fallback

  return children
}

export default Protect
