import http from '@/lib/http'
import { useSuspenseQuery } from '@tanstack/react-query'
import type { MedRequest } from '@/types/data'

const useAllMedRequestHistory = () => {
  return useSuspenseQuery<MedRequest[]>({
    queryKey: ['allMedRequestHistory'],
    queryFn: () => http.get('/prescriber/med-requests/history'),
    staleTime: 0,
    refetchOnMount: true
  })
}

export default useAllMedRequestHistory
