import http from '@/lib/http'
import type { MedRequest } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

const usePatientOrderHistory = (patientId: string, enabled?: boolean) => {
  return useQuery<Array<MedRequest>>({
    queryKey: ['patientOrderHistory', patientId],
    queryFn: async () => {
      const url = `/prescriber/med-requests/history/${patientId}`
      return http.get(url)
    },
    enabled
  })
}

export default usePatientOrderHistory
