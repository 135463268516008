import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import type { SCRData } from '@/lib/stores/medication-request'

function Section({
  header,
  id,
  data
}: {
  header: string
  id?: string
  data: SCRData[] | string[]
}) {
  const isDataString = typeof data === 'string'
  return (
    <div id={id}>
      <div className='flex h-[59px] p-4 px-6 items-center rounded-2xl border border-custom-neutral-200 bg-custom-gray-3'>
        <p className='text-[#080F30] text-base font-semibold leading-[170%]'>{header}</p>
      </div>
      <ul className='pt-4 px-12 list-disc'>
        {isDataString ? (
          <li className='text-custom-neutral-800 text-base font-normal leading-[170%] mb-4'>
            <span>{data}</span>
          </li>
        ) : (
          data.map((item, index) => {
            //	console.log("item", item);
            const isItemString = typeof item === 'string'

            const [date, ...rest] = Object.values(item)

            return (
              <li key={index} className='text-custom-neutral-800 text-base font-normal leading-[170%] mb-4'>
                {isItemString ? (
                  <span>{item}</span>
                ) : (
                  <>
                    <span className='font-semibold'>{date}: </span>
                    {rest.join(', ')}
                  </>
                )}
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}

export default function SCRDataSection() {
  const fieldNamesToSkip = ['nhs_number', 'postcode']

  const { data } = useCurrentMedication()

  const scrData = data.patient?.scrData ? JSON.parse(data.patient.scrData) : null

  return (
    <div>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>SCR Data</p>
      <div className='flex flex-col gap-6'>
        {scrData ? (
          Object.entries(scrData).map(([key, value]) => {
            if (fieldNamesToSkip.includes(key)) {
              return null
            }

            return (
              <Section
                key={key}
                data={value as SCRData[] | string[]}
                id={`scr-section-${key}`}
                header={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              />
            )
          })
        ) : (
          <p className='text-custom-neutral-800 text-base font-normal leading-[170%]'>No SCR data found.</p>
        )}
      </div>
    </div>
  )
}
