import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export default function ErrorPage() {
  const error = useRouteError() as {
    statusText?: string
    message?: string
  }

  useEffect(() => {
    console.log('capturing global error', error)
    Sentry.captureException(error)
  }, [error])

  return (
    <div className='flex flex-col gap-3'>
      <p>Woops, an error has occurred</p>
      <i>{error.statusText || error.message}</i>
    </div>
  )
}
