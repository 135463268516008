import type { MedRequest } from '@/types/data'
import { type ClassValue, clsx } from 'clsx'
import { differenceInYears } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import { useMedicationRequestStore } from './stores/medication-request'
import type { useToast } from '@/hooks/use-toast'
import { toast } from '@/hooks/use-toast'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getOrderStatusBadgeClasses(status: string) {
  const greenStatuses = [
    'Approved for future',
    'Approved',
    'Awaiting post payment',
    'Awaiting fulfillment',
    'Picked',
    'Dispensed',
    'Checked',
    'Packed',
    'Shipped',
    'Completed'
  ]

  const amberStatuses = ['Further answers needed']

  const neutralStatuses = ['ID verified']

  if (greenStatuses.includes(status)) {
    return 'border-[#388E10] bg-[rgba(225,241,218,0.50)] text-[#388E10]'
  }
  if (amberStatuses.includes(status)) {
    return 'border-[#CC8800] bg-[#FFF1D6] text-[#CC8800]'
  }
  if (neutralStatuses.includes(status)) {
    return 'border-[#4E545E] bg-[rgba(255,255,255,0.50)] text-[#24272B]'
  }

  return 'border-[#FF4D1A] bg-[rgba(255,224,214,0.50)] text-[#FF4D1A]'
}

export function getPatientAgeText(dateOfBirth?: string) {
  if (!dateOfBirth) return '-'
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  const age = differenceInYears(today, birthDate)
  return age === 1 ? `${age} year old` : `${age} years old`
}

export function isMedRequestInEditableStage(stage: string) {
  const nonEditableStages = [
    'Packed',
    'Shipped',
    'Completed',
    'Refunded',
    'Returned',
    'Cancelled',
    'Payment Received',
    'ID Not Verified'
  ].map(entry => entry.toLowerCase())

  return !nonEditableStages.includes(stage.toLowerCase())
}

export function isRoleAllowedToEdit(medRequest: MedRequest, roles: string[]) {
  if (medRequest.OTC === false && roles.includes('PHARMACIST')) {
    return false
  }

  return true
}

export function isMedRequestSubscription(subFrequency: string | null) {
  return !!(subFrequency && subFrequency !== 'one-time-purchase')
}

export function getMedRequestSubscriptionText(subFrequency: string | null) {
  if (!subFrequency) return ''

  return subFrequency.replace(/_/g, ' ')
}

export function fieldValidationNotifications(action: 'approve' | 'reject' | 'hold'): boolean {
  const { justification, medicationDirection, rejectionReason, contactMessage } = useMedicationRequestStore.getState()

  if (medicationDirection.length > 2000) {
    toast({
      title: 'Medication directions too long',
      description: 'Medication directions must be less than 2000 characters in total'
    })
    return false
  }

  if (action === 'approve' && justification && justification.length > 200) {
    toast({
      title: 'Approval justification too long',
      description: 'Justification must be less than 200 characters in total'
    })
    return false
  }

  if (action === 'reject' && rejectionReason && rejectionReason.length > 1900) {
    toast({
      title: 'Rejection reason too long',
      description: 'Rejection reason must be less than 1900 characters in total'
    })
    return false
  }

  if (action === 'hold' && contactMessage && contactMessage.length > 1900) {
    toast({
      title: 'Contact message too long',
      description: 'Contact message must be less than 1900 characters in total'
    })
    return false
  }

  return true
}
