export default function StarIcon({ ...props }) {
  return (
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.983 0.333496C7.78301 0.333496 0.333008 7.80016 0.333008 17.0002C0.333008 26.2002 7.78301 33.6668 16.983 33.6668C26.1997 33.6668 33.6663 26.2002 33.6663 17.0002C33.6663 7.80016 26.1997 0.333496 16.983 0.333496ZM22.3663 25.9835L16.9997 22.7502L11.633 25.9835C10.9997 26.3668 10.2163 25.8002 10.383 25.0835L11.7997 18.9835L7.08301 14.9002C6.53301 14.4168 6.83301 13.5002 7.56634 13.4335L13.7997 12.9002L16.233 7.15016C16.5163 6.46683 17.483 6.46683 17.7663 7.15016L20.1997 12.8835L26.433 13.4168C27.1663 13.4835 27.4663 14.4002 26.8997 14.8835L22.183 18.9668L23.5997 25.0835C23.7663 25.8002 22.9997 26.3668 22.3663 25.9835Z'
        fill='#153CB7'
      />
    </svg>
  )
}
