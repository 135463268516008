import { PrescribingHistoryTable } from './table'

export default function PrescribingHistoryPage() {
  return (
    <section className='flex flex-col gap-8'>
      <div className='flex items-start justify-between'>
        <div className='flex flex-col gap-2'>
          <h2 className='capitalize text-[2rem] font-semibold leading-10 text-secondary'>Prescribing History</h2>

          <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
            View the prescribing history over the last 7 days.
          </p>
        </div>
      </div>
      <PrescribingHistoryTable />
    </section>
  )
}
