import { ClerkLoaded, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react'
import { Suspense } from 'react'
import { Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom'
import Protect from './components/Protect.tsx'
import { ErrorBoundary } from './components/common/ErrorBoundary.tsx'
import DashboardPage from './components/pages/dashboard/index.tsx'
import ErrorPage from './components/pages/error.tsx'
import RootLayout from './components/pages/layout.tsx'
import MedicationRequestPage from './components/pages/medication-request/index.tsx'
import PrescribingHistoryPage from './components/pages/prescribing-history/index.tsx'
import useScreenshotAlert from './hooks/useScreenshotAlert.ts'

const router = createBrowserRouter([
  {
    element: (
      <RootLayout>
        <ScrollRestoration />
        <Suspense fallback='Loading...'>
          <Protect
            roles={['LEAD_PRESCRIBER', 'PHARMACIST', 'PRESCRIBER', 'JUNIOR_PRESCRIBER']}
            fallback='Missing presciberID or role configuration, please wait untill it is fixed'
          >
            <ErrorBoundary fallback='Error loading the page'>
              <Outlet />
            </ErrorBoundary>
          </Protect>
        </Suspense>
      </RootLayout>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <DashboardPage />
      },
      {
        path: '/history',
        element: <PrescribingHistoryPage />
      },
      {
        path: '/request/:id',
        element: <MedicationRequestPage />
      },
      {
        path: '*',
        element: <h1>Route Not found</h1>
      }
    ]
  }
])

function App() {
  useScreenshotAlert()

  return (
    <ClerkLoaded>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        <RouterProvider router={router} />
      </SignedIn>
    </ClerkLoaded>
  )
}

export default App
