import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'

// TODO: update chevrons here by abstracting accordion compoennt

const getSections = (
  scrData: Record<string, unknown> | null,
  hasOnHoldHistory: boolean | null,
  hasPreviousOrders?: boolean
) => {
  const scrDataSubFields = scrData
    ? Object.keys(scrData)
        .filter(key => !['date_of_birth', 'first_name', 'last_name', 'nhs_number', 'postcode'].includes(key))
        .map(key => ({
          id: `scr-section-${key}`,
          label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
        }))
    : []

  const sections = [
    {
      title: 'Patient data',
      subfields: [{ id: 'patient-data', label: 'Patient data' }]
    },
    hasPreviousOrders
      ? {
          title: 'Previous orders',
          subfields: [{ id: 'previous-orders', label: 'Previous orders' }]
        }
      : false,
    hasOnHoldHistory
      ? {
          title: 'On hold history',
          subfields: [{ id: 'on-hold-history', label: 'On hold history' }]
        }
      : false,
    {
      title: 'Consultation',
      subfields: [{ id: 'consultation', label: 'Consultation' }]
    },
    {
      title: 'SCR data',
      subfields: scrDataSubFields
    },
    {
      title: 'Prescription details',
      subfields: [{ id: 'prescription-details', label: 'Prescription Details' }]
    },
    /*   {
      title: "Prescriber justification",
      subfields: [{ id: "add-justification", label: "Add justification" }],
    },
  */
    {
      title: 'Additional notes',
      subfields: [{ id: 'add-notes', label: 'Add Notes' }]
    }
  ].filter(Boolean) as {
    title: string
    subfields: Array<{ id: string; label: string }>
  }[]

  return sections
}

const scrollToSection = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export default function NavigationMenu() {
  const { data } = useCurrentMedication()
  const { data: previousOrders } = usePatientOrderHistory(data.patient.id)
  const scrData = data.patient?.scrData ? JSON.parse(data.patient.scrData) : null

  const sections = getSections(
    scrData,
    Boolean(data?.reasonForHold || data?.patientsResponseForHold),
    !!previousOrders?.length
  )

  return (
    <div className='sticky py-2 overflow-y-auto top-[88px] min-h-[270px] max-h-[68vh] flex w-[300px] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6 mb-4'>
        <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Navigation Menu</p>
      </div>

      <Accordion type='single' collapsible>
        {sections.map((section, index) => (
          <AccordionItem key={index} value={`item-${index + 1}`}>
            <AccordionTrigger
              chevronDisabled={section.subfields.length <= 1}
              className='py-6 px-7 h-[69px] text-[#393E45] font-medium leading-[133.4%] flex items-center data-[state=open]:bg-custom-neutral-700'
              onClick={() =>
                section.subfields.length <= 1 && section.subfields[0]?.id && scrollToSection(section.subfields[0].id)
              }
            >
              <span>• {section.title}</span>
            </AccordionTrigger>

            {section.subfields.length > 1 && (
              <AccordionContent className='px-14 pb-3'>
                {section.subfields.map((subfield, subIndex) => (
                  <button
                    type='button'
                    onClick={() => scrollToSection(subfield.id)}
                    key={subIndex}
                    className='flex text-custom-neutral-800 leading-[170%] pt-3 truncate'
                  >
                    • {subfield.label}
                  </button>
                ))}
              </AccordionContent>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
