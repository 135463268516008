import { useToast } from '@/hooks/use-toast'
import http from '@/lib/http'
import { queryClient } from '@/services/queryClient'
import type { UserProfile } from '@/types/data'
import { useMutation } from '@tanstack/react-query'

interface UploadResponse {
  success: boolean
  data?: {
    url: string
    key: string
  }
  details?: string
  error?: string
}

const useUploadSignatureImage = () => {
  const { toast } = useToast()

  return useMutation({
    mutationKey: ['uploadSignatureImage'],
    mutationFn: async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      await http.post<UploadResponse>('/prescriber/upload/signature', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      toast({
        title: 'Success',
        description: 'Signature image uploaded successfully'
      })
    },
    onMutate(file: File) {
      const oldProfile = queryClient.getQueryData<UserProfile>(['userProfile'])
      queryClient.setQueryData(['userProfile'], {
        ...oldProfile,
        signatureImageLink: URL.createObjectURL(file)
      })
      return { oldProfile }
    },
    onError: (error, newProfile, context) => {
      queryClient.setQueryData(['userProfile'], context?.oldProfile)
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error?.message ?? 'Failed to upload signature'
      })
    }
  })
}

export default useUploadSignatureImage
