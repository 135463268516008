import { cn } from '@/lib/utils'

export const LoadingSpinner = ({ ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='105'
      height='104'
      viewBox='0 0 105 104'
      fill='none'
      className={cn('text-[#4E545E]', props.className)}
      {...props}
    >
      <path d='M52.5 13V26' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      <path opacity='0.4' d='M52.5 78V91' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      <path opacity='0.4' d='M91.5 52H78.5' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      <path d='M26.5 52H13.5' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      <path
        opacity='0.4'
        d='M80.0751 24.4258L70.8828 33.6182'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path d='M34.1182 70.3828L24.9258 79.5751' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      <path
        opacity='0.4'
        d='M80.0751 79.5751L70.8828 70.3828'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path d='M34.1182 33.6182L24.9258 24.4258' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
    </svg>
  )
}

export default LoadingSpinner
