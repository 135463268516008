import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { isMedRequestInEditableStage } from '@/lib/utils'
import MedicationRequestChip from '../med-request-chip'
import { format } from 'date-fns'
import { OrderStatusBadge } from './previous-orders'
import Lock2 from '@/components/ui/icons/lock2'

const Header = () => {
  const { data } = useCurrentMedication()

  const isInEditableStage = isMedRequestInEditableStage(data.stage)

  const medNameStringSplitted = data.orderMedNameString.split(' ')

  const [_, ...splittedMedName] = medNameStringSplitted

  return (
    <>
      {data.locked && (
        <div className='flex items-center rounded-2xl border border-[#FF8080] bg-[#FFF2F2] gap-4 px-6 py-4'>
          <Lock2 />
          <p className='text-[#080F30] font-poppins text-base font-normal leading-[170%]'>
            This medication request is currently <span className='font-semibold'>LOCKED</span>. You can't make any
            changes to it at the moment.
          </p>
        </div>
      )}
      {!isInEditableStage && (
        <>
          <div className='mb-20 rounded-2xl border border-[#E9EAED] bg-[#FFFAEB] py-4 px-6'>
            <p className='text-[#080F30] leading-[170%]'>
              You are viewing a <b>PREVIOUS</b> medication request. This page is <b>read-only</b> and cannot be edited.
            </p>
          </div>
          <section className='flex flex-col gap-8'>
            <div className='flex items-start justify-between'>
              <div className='flex items-center gap-2 justify-between w-full'>
                <div className='flex items-center'>
                  <span className='mr-4 capitalize text-[2rem] font-semibold leading-10 text-secondary'>
                    {splittedMedName.join(' ')}
                  </span>
                  <span className='text-[#4E545E] text-xl leading-[170%]'>{data.orderedItem.category}</span>
                </div>
                <div className='flex items-center gap-6'>
                  <div>
                    <p className='text-[#1D1D1D] text-sm font-bold leading-[170%]'>Completed date :</p>
                    <p className='text-[#4E545E] text-sm  leading-[170%]'>
                      {format(new Date(data.closingDate), 'dd MMMM yyyy')}
                    </p>
                  </div>
                  <OrderStatusBadge stage={data.stage} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <MedicationRequestChip />
    </>
  )
}

export default Header
