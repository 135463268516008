import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { Fragment } from 'react/jsx-runtime'

function Column({ header, text }: { header: string; text: string }) {
  const parseText = (text: string) => {
    const regex = /(https:\/\/workdrive\.zohoexternal\.com\/external\S+)|\S+/g
    const chunks = text.match(regex) || []

    return chunks.map((chunk, index) => {
      if (chunk.startsWith('https://workdrive.zohoexternal.com/external')) {
        return (
          <a
            key={index}
            href={chunk}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-600 hover:underline'
          >
            {chunk}
          </a>
        )
      }

      return <Fragment key={index}>{chunk} </Fragment>
    })
  }

  return (
    <div className='w-2/5 break-words h-[120px]  overflow-y-auto'>
      <p className='text-[#1D1D1D] text-sm font-bold leading-[170%] mb-2'>{header}</p>
      <p className='text-custom-neutral-800 text-sm leading-[170%]'>{parseText(text)}</p>
    </div>
  )
}

export default function OnHoldHistory() {
  const { data } = useCurrentMedication()

  if (!data.reasonForHold && !data.patientsResponseForHold) return null

  const reasonForHold = data.reasonForHold ?? '-'
  const patientsResponse = data.patientsResponseForHold ?? '-'

  return (
    <div id='on-hold-history'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>On hold history</p>
      <div className='flex h-[216px] w-full p-8 justify-between items-center gap-4 rounded-3xl border border-custom-neutral-200 bg-[#FAFAFB]'>
        <Column header='On Hold reason' text={reasonForHold} />
        <div className='h-full w-[1px] bg-custom-neutral-200' />
        <Column header='Patient response' text={patientsResponse} />
      </div>
    </div>
  )
}
