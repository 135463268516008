type Props = {
  orderMedNameString: string
}

export const Request = ({ orderMedNameString }: Props) => {
  return (
    <p
      title={orderMedNameString}
      className='rounded-2xl font-medium py-[8.5px] px-3 text-sm border border-t border-custom-neutral-200 max-w-64 truncate'
    >
      {orderMedNameString}
    </p>
  )
}
