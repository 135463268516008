import { useTotalMedicationRequests } from '@/services/medicationRequests'
import { useEffect, useRef } from 'react'

const notificationSound = new Audio('/sounds/notification2.wav')

export function MedicationRequestInfo() {
  const { data } = useTotalMedicationRequests()

  const previousDataLength = useRef(0)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    if (!data) return

    if (isInitialLoad.current) {
      isInitialLoad.current = false
      previousDataLength.current = data.total
      return
    }

    const currentLength = data.total

    if (previousDataLength.current === 0 && currentLength > 0) {
      notificationSound.play()
    }

    previousDataLength.current = currentLength

    return () => {
      if (!notificationSound.paused) {
        notificationSound.pause()
        notificationSound.currentTime = 0
      }
    }
  }, [data])

  return (
    <div className='flex gap-[4rem]'>
      <div className='px-6 py-8 rounded-3xl bg-custom-gray-3 flex items-center gap-4 border border-custom-neutral-200 w-full'>
        <div className='py-3 px-4 rounded-full text-white bg-secondary text-xl leading-7 font-bold tracking-[0.15px]'>
          {data.beforeCutOff}
        </div>

        <p className='text-custom-neutral-900 text-xl font-medium leading-7 tracking-[0.15px] max-w-80'>
          Total medication requests due before the delivery cutoff
        </p>
      </div>

      <div className='px-6 py-8 rounded-3xl bg-custom-gray-3 flex items-center gap-4 border border-custom-neutral-200 w-full'>
        <div className='py-3 px-4 rounded-full text-white bg-secondary text-xl leading-7 font-bold tracking-[0.15px]'>
          {data.total}
        </div>

        <p className='text-custom-neutral-900 text-xl font-medium leading-7 tracking-[0.15px] max-w-80'>
          Total remaining medication requests
        </p>
      </div>
    </div>
  )
}
