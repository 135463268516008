import { cn } from '@/lib/utils'

export const Loading = ({ ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='89'
      height='88'
      viewBox='0 0 89 88'
      className={cn(props.className)}
      {...props}
    >
      <path
        opacity='0.4'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M25.9585 3.48047C19.3353 3.48047 12.7379 8.07239 12.4324 15.5291C12.0901 23.8875 18.0633 29.1908 22.6099 33.0477C25.9339 35.8674 28.167 37.7703 29.6492 39.3833C31.0879 40.949 31.3964 41.804 31.4571 42.4259C31.4878 42.7394 31.4878 43.0547 31.4571 43.3682C31.3964 43.9905 31.0879 44.8452 29.6492 46.4108C28.167 48.0238 25.9339 49.9268 22.6099 52.7465L22.4218 52.906C17.8157 56.8117 12.0805 61.6748 12.4324 70.2651C12.7379 77.722 19.3353 82.3137 25.9585 82.3137H63.0423C69.6654 82.3137 76.2628 77.722 76.5683 70.2651C76.774 65.2469 74.9212 60.133 70.8644 56.5462C69.8531 55.6523 68.7876 54.7595 67.7664 53.904L67.762 53.9004C67.2923 53.5066 66.8318 53.1208 66.3907 52.7465C63.0669 49.9268 60.8339 48.0238 59.3514 46.4108C57.9126 44.8452 57.6043 43.9905 57.5438 43.3682C57.513 43.0547 57.513 42.7394 57.5438 42.4259C57.6043 41.804 57.9126 40.949 59.3514 39.3833C60.8339 37.7703 63.0669 35.8674 66.3907 33.0477L66.4483 32.9989C71.0342 29.1089 76.9199 24.1162 76.5683 15.5291C76.2628 8.07239 69.6654 3.48047 63.0423 3.48047H25.9585ZM30.8266 72.9205C30.3145 73.7979 30.2156 75.1472 31.6447 75.1472H57.3593C58.7882 75.1472 58.6896 73.7979 58.1774 72.9205C57.3703 71.5381 56.061 70.6123 55.0358 69.8874C51.8953 67.6438 48.5648 64.8963 44.4996 64.8971C40.4362 64.8982 37.107 67.6449 33.9682 69.8874C32.9429 70.6123 31.6334 71.5381 30.8266 72.9205Z'
        fill='#E8AE03'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M30.8224 72.9204C30.3103 73.7978 30.2114 75.1471 31.6405 75.1471H57.355C58.7843 75.1471 58.6853 73.7978 58.173 72.9204C57.3664 71.538 56.0566 70.6122 55.0314 69.8873C51.8913 67.6437 48.5605 64.8962 44.4953 64.897C40.4322 64.8981 37.1028 67.6448 33.964 69.8873C32.9387 70.6122 31.6292 71.538 30.8224 72.9204Z'
        fill='#E8AE03'
      />
    </svg>
  )
}
