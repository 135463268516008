export default function Logo({ ...props }) {
  return (
    <svg width='144' height='28' viewBox='0 0 144 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect y='0.357971' width='27.7713' height='27.2841' rx='2.78409' fill='#153CB7' />
      <path
        d='M7.30456 15.9051C7.49051 19.3669 9.60106 22.2554 12.9513 23.5942C14.2127 24.0963 15.5422 24.3535 16.9028 24.3535C19.4379 24.3535 21.8429 23.4641 23.6745 21.8494C23.8263 21.7161 23.91 21.5301 23.9131 21.3256C23.9162 21.118 23.8325 20.9165 23.6838 20.774L22.0536 19.2244C21.7809 18.9671 21.3687 18.9578 21.0681 19.2027C19.2488 20.6903 16.6021 21.0653 14.3242 20.1541C13.6796 19.8969 12.3655 19.1903 11.6496 17.9475C12.8552 18.2109 14.2312 18.3008 15.5453 18.2016C18.6817 17.9599 21.0898 16.5095 22.1528 14.2254C23.0422 12.307 22.8377 10.0632 21.6135 8.36481C20.3274 6.57967 18.1982 5.715 15.7653 5.98773C12.5763 6.34723 9.61966 8.3927 8.21263 11.4113C8.15064 11.3059 8.04217 11.1231 7.98948 11.0146C7.1527 9.39684 7.10931 7.54972 7.87791 6.19537C8.04837 5.89475 7.97399 5.51045 7.70126 5.28421L5.96261 3.83999C5.82935 3.72842 5.6279 3.65714 5.44195 3.67573C5.19092 3.70053 4.98637 3.83379 4.8686 4.01664C3.26942 6.4929 3.20434 9.82762 4.70124 12.7192C5.34588 13.965 6.23534 15.0467 7.30766 15.9082M14.8356 14.526C13.6207 14.5786 12.2571 14.4144 11.2002 13.9433C11.8759 11.4113 14.2529 9.88961 16.1837 9.67266C17.2623 9.5487 18.1238 9.85551 18.6104 10.5342C19.0505 11.1448 19.1094 11.9598 18.7933 12.6664C18.2354 13.9185 16.6982 14.4454 14.8356 14.526Z'
        fill='white'
      />
      <path
        d='M35.8487 21.3741C35.5503 21.3741 35.3083 21.1322 35.3083 20.8338V7.7672C35.3083 7.46878 35.5503 7.22686 35.8487 7.22686H40.8899C41.9629 7.22686 42.877 7.43179 43.6323 7.84166C44.3876 8.24692 44.9632 8.81106 45.3593 9.53408C45.7599 10.2525 45.9602 11.0814 45.9602 12.0209C45.9602 12.9604 45.7576 13.7893 45.3523 14.5077C44.9471 15.2261 44.3599 15.7857 43.5908 16.1863C42.8264 16.587 41.9007 16.7873 40.8139 16.7873H37.7967C37.4983 16.7873 37.2564 16.5454 37.2564 16.247V14.9306C37.2564 14.6322 37.4983 14.3903 37.7967 14.3903H40.3303C40.906 14.3903 41.3803 14.2913 41.7534 14.0933C42.131 13.8906 42.4119 13.612 42.5961 13.2574C42.7849 12.8982 42.8793 12.486 42.8793 12.0209C42.8793 11.5512 42.7849 11.1413 42.5961 10.7913C42.4119 10.4367 42.131 10.1627 41.7534 9.96927C41.3757 9.77125 40.8968 9.67223 40.3165 9.67223H38.8398C38.5414 9.67223 38.2994 9.91416 38.2994 10.2126V20.8338C38.2994 21.1322 38.0575 21.3741 37.7591 21.3741H35.8487Z'
        fill='#153CB7'
      />
      <path
        d='M48.3882 21.3741C48.0897 21.3741 47.8478 21.1322 47.8478 20.8338V11.304C47.8478 11.0056 48.0897 10.7637 48.3882 10.7637H50.1604C50.4588 10.7637 50.7007 11.0056 50.7007 11.304V12.5518C50.7007 12.5867 50.729 12.615 50.764 12.615C50.792 12.615 50.8167 12.5964 50.8249 12.5696C51.0198 11.9335 51.3399 11.4513 51.7853 11.1229C52.2412 10.7821 52.7662 10.6117 53.3603 10.6117C53.5076 10.6117 53.6665 10.6209 53.8369 10.6393C53.8482 10.6406 53.8594 10.6418 53.8706 10.6431C54.1169 10.6715 54.2859 10.8893 54.2859 11.1372V12.742C54.2859 13.0333 54.0014 13.2541 53.7126 13.216C53.4685 13.1837 53.2451 13.1676 53.0425 13.1676C52.6096 13.1676 52.2228 13.262 51.882 13.4508C51.5458 13.635 51.2787 13.8929 51.0807 14.2245C50.8873 14.5561 50.7905 14.9383 50.7905 15.3712V20.8338C50.7905 21.1322 50.5486 21.3741 50.2502 21.3741H48.3882Z'
        fill='#153CB7'
      />
      <path
        d='M60.1489 21.5814C59.0575 21.5814 58.118 21.3603 57.3305 20.9182C56.5477 20.4715 55.9444 19.8406 55.5207 19.0254C55.097 18.2057 54.8852 17.2363 54.8852 16.1172C54.8852 15.0258 55.097 14.0679 55.5207 13.2436C55.9444 12.4193 56.5407 11.7768 57.3098 11.3163C58.0835 10.8558 58.9907 10.6255 60.0315 10.6255C60.7315 10.6255 61.3831 10.7383 61.9864 10.964C62.5943 11.1851 63.1239 11.5189 63.5752 11.9656C64.0311 12.4123 64.3857 12.9742 64.639 13.6511C64.8923 14.3235 65.019 15.111 65.019 16.0136V16.2815C65.019 16.5799 64.777 16.8218 64.4786 16.8218H56.5998C56.3014 16.8218 56.0595 16.5799 56.0595 16.2815V15.5385C56.0595 15.2401 56.3014 14.9982 56.5998 14.9982H61.7086C62.007 14.9982 62.2571 14.7533 62.1943 14.4616C62.1488 14.2502 62.0749 14.0538 61.9726 13.8722C61.7884 13.5452 61.5328 13.2896 61.2058 13.1054C60.8835 12.9166 60.5081 12.8222 60.0799 12.8222C59.6332 12.8222 59.2371 12.9258 58.8917 13.1331C58.5509 13.3357 58.2838 13.6097 58.0904 13.9551C57.897 14.2959 57.798 14.6758 57.7934 15.0949V16.8288C57.7934 17.3538 57.8901 17.8074 58.0835 18.1896C58.2815 18.5718 58.5601 18.8666 58.9193 19.0738C59.2786 19.281 59.7045 19.3847 60.1973 19.3847C60.5243 19.3847 60.8236 19.3386 61.0953 19.2465C61.367 19.1544 61.5996 19.0162 61.793 18.832C61.8933 18.7365 61.9812 18.6299 62.0567 18.5121C62.1849 18.3121 62.397 18.1657 62.6341 18.1814L64.3349 18.2936C64.6631 18.3153 64.8991 18.625 64.7815 18.9321C64.6243 19.3427 64.3995 19.7146 64.1071 20.0478C63.6835 20.5314 63.1354 20.909 62.4631 21.1807C61.7953 21.4478 61.0239 21.5814 60.1489 21.5814Z'
        fill='#153CB7'
      />
      <path
        d='M75.6499 13.2224C75.7372 13.5232 75.5019 13.8057 75.1892 13.8249L73.5441 13.9261C73.2745 13.9427 73.0438 13.7491 72.9058 13.5169C72.8681 13.4534 72.825 13.3922 72.7765 13.3334C72.6245 13.1446 72.4242 12.9949 72.1755 12.8844C71.9314 12.7692 71.639 12.7117 71.2982 12.7117C70.8423 12.7117 70.4577 12.8084 70.1446 13.0018C69.8314 13.1906 69.6749 13.4439 69.6749 13.7617C69.6749 14.015 69.7762 14.2291 69.9788 14.4041C70.1814 14.5791 70.5291 14.7196 71.0219 14.8255L72.9423 15.2123C73.9738 15.4242 74.7429 15.7649 75.2495 16.2347C75.7561 16.7044 76.0093 17.3215 76.0093 18.086C76.0093 18.7814 75.8044 19.3916 75.3945 19.9166C74.9893 20.4416 74.4321 20.8514 73.7228 21.1462C73.0182 21.4363 72.2054 21.5814 71.2844 21.5814C69.8798 21.5814 68.7607 21.2889 67.9272 20.7041C67.2621 20.2311 66.8179 19.6218 66.5945 18.8761C66.5035 18.5724 66.742 18.2858 67.0586 18.2692L68.8994 18.1725C69.1665 18.1585 69.3927 18.3488 69.5147 18.5868C69.6277 18.8072 69.7893 18.9879 69.9995 19.1291C70.3357 19.3501 70.7663 19.4606 71.2913 19.4606C71.8071 19.4606 72.2215 19.3616 72.5347 19.1636C72.8525 18.961 73.0136 18.7008 73.0182 18.383C73.0136 18.1159 72.9008 17.8972 72.6798 17.7268C72.4587 17.5518 72.1179 17.4182 71.6574 17.3261L69.8199 16.96C68.7837 16.7528 68.0124 16.3936 67.5058 15.8824C67.0038 15.3712 66.7528 14.7196 66.7528 13.9275C66.7528 13.2459 66.937 12.6587 67.3055 12.166C67.6785 11.6732 68.2012 11.2933 68.8735 11.0262C69.5505 10.7591 70.3426 10.6255 71.2498 10.6255C72.59 10.6255 73.6446 10.9087 74.4136 11.4752C75.0309 11.9271 75.443 12.5095 75.6499 13.2224Z'
        fill='#153CB7'
      />
      <path
        d='M82.6564 21.5814C81.5696 21.5814 80.6347 21.3511 79.8518 20.8906C79.0735 20.4254 78.4749 19.7807 78.0558 18.9564C77.6413 18.132 77.4341 17.1834 77.4341 16.1103C77.4341 15.0235 77.6436 14.0702 78.0627 13.2505C78.4864 12.4262 79.0874 11.7837 79.8656 11.3232C80.6439 10.8581 81.5696 10.6255 82.6426 10.6255C83.5682 10.6255 84.3788 10.7936 85.0742 11.1298C85.7695 11.466 86.3199 11.938 86.7251 12.5459C87.0343 13.0097 87.2377 13.5352 87.3351 14.1224C87.3857 14.4274 87.136 14.6873 86.8268 14.6873H85.0846C84.8167 14.6873 84.5966 14.488 84.5114 14.234C84.4024 13.9088 84.2307 13.634 83.9965 13.4094C83.665 13.0824 83.2298 12.9189 82.6909 12.9189C82.235 12.9189 81.8367 13.0433 81.4959 13.2919C81.1597 13.536 80.8972 13.8929 80.7084 14.3627C80.5196 14.8324 80.4252 15.4011 80.4252 16.0689C80.4252 16.7459 80.5173 17.3215 80.7015 17.7959C80.8903 18.2702 81.1551 18.6317 81.4959 18.8804C81.8367 19.1291 82.235 19.2534 82.6909 19.2534C83.0271 19.2534 83.3288 19.1843 83.5959 19.0462C83.8676 18.908 84.0909 18.7077 84.2659 18.4452C84.368 18.2934 84.45 18.1244 84.512 17.9383C84.5965 17.6842 84.8166 17.485 85.0844 17.485H86.8233C87.1337 17.485 87.3838 17.7471 87.3316 18.0531C87.2327 18.6333 87.0328 19.1578 86.732 19.6264C86.3406 20.2389 85.7995 20.7179 85.1087 21.0633C84.4179 21.4087 83.6005 21.5814 82.6564 21.5814Z'
        fill='#153CB7'
      />
      <path
        d='M89.8196 21.3741C89.5212 21.3741 89.2793 21.1322 89.2793 20.8338V11.304C89.2793 11.0056 89.5212 10.7637 89.8196 10.7637H91.5919C91.8903 10.7637 92.1322 11.0056 92.1322 11.304V12.5518C92.1322 12.5867 92.1605 12.615 92.1954 12.615C92.2235 12.615 92.2482 12.5964 92.2564 12.5696C92.4513 11.9335 92.7714 11.4513 93.2168 11.1229C93.6727 10.7821 94.1977 10.6117 94.7918 10.6117C94.9391 10.6117 95.098 10.6209 95.2684 10.6393C95.2797 10.6406 95.2909 10.6418 95.302 10.6431C95.5484 10.6715 95.7174 10.8893 95.7174 11.1372V12.742C95.7174 13.0333 95.4328 13.2541 95.1441 13.216C94.9 13.1837 94.6766 13.1676 94.474 13.1676C94.0411 13.1676 93.6543 13.262 93.3135 13.4508C92.9773 13.635 92.7102 13.8929 92.5122 14.2245C92.3188 14.5561 92.222 14.9383 92.222 15.3712V20.8338C92.222 21.1322 91.9801 21.3741 91.6817 21.3741H89.8196Z'
        fill='#153CB7'
      />
      <path
        d='M97.7792 21.3741C97.4808 21.3741 97.2389 21.1322 97.2389 20.8338V11.304C97.2389 11.0056 97.4808 10.7637 97.7792 10.7637H99.6413C99.9397 10.7637 100.182 11.0056 100.182 11.304V20.8338C100.182 21.1322 99.9397 21.3741 99.6413 21.3741H97.7792ZM98.7171 9.39592C98.2796 9.39592 97.9043 9.25085 97.5912 8.96073C97.2826 8.66599 97.1283 8.31369 97.1283 7.90383C97.1283 7.49857 97.2826 7.15087 97.5912 6.86074C97.9043 6.56601 98.2796 6.41864 98.7171 6.41864C99.1546 6.41864 99.5277 6.56601 99.8362 6.86074C100.149 7.15087 100.306 7.49857 100.306 7.90383C100.306 8.31369 100.149 8.66599 99.8362 8.96073C99.5277 9.25085 99.1546 9.39592 98.7171 9.39592Z'
        fill='#153CB7'
      />
      <path
        d='M103.135 21.3741C102.836 21.3741 102.594 21.1322 102.594 20.8338V7.7672C102.594 7.46878 102.836 7.22686 103.135 7.22686H104.997C105.295 7.22686 105.537 7.46878 105.537 7.7672V12.4913C105.537 12.5215 105.561 12.5459 105.591 12.5459C105.613 12.5459 105.632 12.5333 105.641 12.5139C105.77 12.2387 105.951 11.9592 106.186 11.6755C106.435 11.3762 106.757 11.1275 107.153 10.9295C107.554 10.7268 108.051 10.6255 108.645 10.6255C109.419 10.6255 110.133 10.8281 110.787 11.2334C111.441 11.6341 111.963 12.2396 112.355 13.0502C112.746 13.8561 112.942 14.8669 112.942 16.0827C112.942 17.2663 112.751 18.2656 112.369 19.0807C111.991 19.8912 111.475 20.506 110.821 20.9251C110.172 21.3396 109.444 21.5468 108.639 21.5468C108.067 21.5468 107.582 21.4524 107.181 21.2636C106.785 21.0748 106.46 20.8376 106.207 20.5521C105.967 20.2773 105.781 20.0004 105.648 19.7214C105.635 19.6931 105.607 19.6748 105.575 19.6748C105.531 19.6748 105.495 19.7105 105.495 19.7546V20.8338C105.495 21.1322 105.254 21.3741 104.955 21.3741H103.135ZM105.475 16.0689C105.475 16.6998 105.562 17.2501 105.737 17.7199C105.912 18.1896 106.166 18.5557 106.497 18.8182C106.829 19.0761 107.232 19.2051 107.706 19.2051C108.185 19.2051 108.59 19.0738 108.922 18.8113C109.253 18.5442 109.504 18.1758 109.675 17.7061C109.85 17.2317 109.937 16.686 109.937 16.0689C109.937 15.4564 109.852 14.9176 109.682 14.4525C109.511 13.9873 109.26 13.6235 108.929 13.361C108.597 13.0985 108.19 12.9673 107.706 12.9673C107.227 12.9673 106.822 13.0939 106.49 13.3472C106.163 13.6005 105.912 13.9597 105.737 14.4248C105.562 14.89 105.475 15.438 105.475 16.0689Z'
        fill='#153CB7'
      />
      <path
        d='M119.741 21.5814C118.65 21.5814 117.71 21.3603 116.923 20.9182C116.14 20.4715 115.537 19.8406 115.113 19.0254C114.689 18.2057 114.477 17.2363 114.477 16.1172C114.477 15.0258 114.689 14.0679 115.113 13.2436C115.537 12.4193 116.133 11.7768 116.902 11.3163C117.676 10.8558 118.583 10.6255 119.624 10.6255C120.324 10.6255 120.975 10.7383 121.579 10.964C122.187 11.1851 122.716 11.5189 123.167 11.9656C123.623 12.4123 123.978 12.9742 124.231 13.6511C124.485 14.3235 124.611 15.111 124.611 16.0136V16.2815C124.611 16.5799 124.369 16.8218 124.071 16.8218H116.192C115.894 16.8218 115.652 16.5799 115.652 16.2815V15.5385C115.652 15.2401 115.894 14.9982 116.192 14.9982H121.301C121.599 14.9982 121.849 14.7533 121.786 14.4616C121.741 14.2502 121.667 14.0538 121.565 13.8722C121.381 13.5452 121.125 13.2896 120.798 13.1054C120.476 12.9166 120.1 12.8222 119.672 12.8222C119.225 12.8222 118.829 12.9258 118.484 13.1331C118.143 13.3357 117.876 13.6097 117.683 13.9551C117.489 14.2959 117.39 14.6758 117.386 15.0949V16.8288C117.386 17.3538 117.482 17.8074 117.676 18.1896C117.874 18.5718 118.152 18.8666 118.512 19.0738C118.871 19.281 119.297 19.3847 119.79 19.3847C120.116 19.3847 120.416 19.3386 120.688 19.2465C120.959 19.1544 121.192 19.0162 121.385 18.832C121.485 18.7365 121.573 18.6299 121.649 18.5121C121.777 18.3121 121.989 18.1657 122.226 18.1814L123.927 18.2936C124.255 18.3153 124.491 18.625 124.374 18.9321C124.217 19.3427 123.992 19.7146 123.699 20.0478C123.276 20.5314 122.728 20.909 122.055 21.1807C121.388 21.4478 120.616 21.5814 119.741 21.5814Z'
        fill='#153CB7'
      />
      <path
        d='M127.072 21.3741C126.773 21.3741 126.532 21.1322 126.532 20.8338V11.304C126.532 11.0056 126.773 10.7637 127.072 10.7637H128.844C129.143 10.7637 129.384 11.0056 129.384 11.304V12.5518C129.384 12.5867 129.413 12.615 129.448 12.615C129.476 12.615 129.5 12.5964 129.509 12.5696C129.703 11.9335 130.024 11.4513 130.469 11.1229C130.925 10.7821 131.45 10.6117 132.044 10.6117C132.191 10.6117 132.35 10.6209 132.521 10.6393C132.532 10.6406 132.543 10.6418 132.554 10.6431C132.801 10.6715 132.97 10.8893 132.97 11.1372V12.742C132.97 13.0333 132.685 13.2541 132.396 13.216C132.152 13.1837 131.929 13.1676 131.726 13.1676C131.293 13.1676 130.907 13.262 130.566 13.4508C130.23 13.635 129.962 13.8929 129.764 14.2245C129.571 14.5561 129.474 14.9383 129.474 15.3712V20.8338C129.474 21.1322 129.232 21.3741 128.934 21.3741H127.072Z'
        fill='#153CB7'
      />
      <path
        d='M143.202 13.2224C143.289 13.5232 143.054 13.8057 142.741 13.8249L141.096 13.9261C140.826 13.9427 140.596 13.7491 140.458 13.5169C140.42 13.4534 140.377 13.3922 140.328 13.3334C140.176 13.1446 139.976 12.9949 139.727 12.8844C139.483 12.7692 139.191 12.7117 138.85 12.7117C138.394 12.7117 138.01 12.8084 137.696 13.0018C137.383 13.1906 137.227 13.4439 137.227 13.7617C137.227 14.015 137.328 14.2291 137.531 14.4041C137.733 14.5791 138.081 14.7196 138.574 14.8255L140.494 15.2123C141.526 15.4242 142.295 15.7649 142.801 16.2347C143.308 16.7044 143.561 17.3215 143.561 18.086C143.561 18.7814 143.356 19.3916 142.946 19.9166C142.541 20.4416 141.984 20.8514 141.275 21.1462C140.57 21.4363 139.757 21.5814 138.836 21.5814C137.432 21.5814 136.312 21.2889 135.479 20.7041C134.814 20.2311 134.37 19.6218 134.146 18.8761C134.055 18.5724 134.294 18.2858 134.61 18.2692L136.451 18.1725C136.718 18.1585 136.944 18.3488 137.066 18.5868C137.179 18.8072 137.341 18.9879 137.551 19.1291C137.887 19.3501 138.318 19.4606 138.843 19.4606C139.359 19.4606 139.773 19.3616 140.086 19.1636C140.404 18.961 140.565 18.7008 140.57 18.383C140.565 18.1159 140.453 17.8972 140.232 17.7268C140.01 17.5518 139.67 17.4182 139.209 17.3261L137.372 16.96C136.336 16.7528 135.564 16.3936 135.058 15.8824C134.556 15.3712 134.305 14.7196 134.305 13.9275C134.305 13.2459 134.489 12.6587 134.857 12.166C135.23 11.6732 135.753 11.2933 136.425 11.0262C137.102 10.7591 137.894 10.6255 138.802 10.6255C140.142 10.6255 141.196 10.9087 141.965 11.4752C142.583 11.9271 142.995 12.5095 143.202 13.2224Z'
        fill='#153CB7'
      />
    </svg>
  )
}
